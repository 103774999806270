import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE, PATIENT_API_ENDPOINTS } from "../config";
import { ROLE_PATIENT } from "../security/roles";
import { store } from "../State/store/configureStore";
import StorageService from "./storageService";
import { closeProgress, showProgress } from "../State/actions/uploadProgress";
import moment from "moment";
export const RECENT_PATIENTS = "RECENT_PATIENTS";

function preProcessFilterFields(criteria) {
  let uiFields = criteria ? criteria.uiFields : {};
  if (uiFields && uiFields.length > 0) {
    uiFields.forEach((attribute) => {
      attribute.operator = attribute.operator || "EQUALS";
    });
  }
  return uiFields;
}

function createFormData(criteria, uiFieldsArr = []) {
  let formData = {
    mode: criteria ? criteria.mode : null,
  };

  if (uiFieldsArr.length > 0) {
    formData.uiFields = uiFieldsArr;
  }

  if (criteria.searchTerm) {
    formData.searchTerm = criteria.searchTerm;
  }

  if (criteria && criteria.sortField) {
    formData.sortField = criteria.sortField;
    formData.sortDirection = criteria.sortDirection;
  }

  if (criteria.recentPatientIds) {
    formData.recentPatientIds = criteria.recentPatientIds;
  }

  if (criteria.statField) {
    formData.statField = criteria.statField;
    formData.stat = criteria.stat;
  }

  if (criteria.showMessageChannels) {
    formData.showChannelsOnly = criteria.showMessageChannels;
  }
  return formData;
}

class PatientsService {
  inviteAdminUser(patientId, data) {
    return http.request(
      API_ENDPOINTS.inviteAdminUser(
        patientId,
        data.hasOwnProperty("loginUserId")
      ),
      "post",
      data
    );
  }

  inviteSpaceMembers(patientId, data) {
    return http.request(
      API_ENDPOINTS.inviteSpaceMember(
        patientId,
        data.hasOwnProperty("loginUserId")
      ),
      "post",
      data
    );
  }

  getSpaceDetails(inviteCode, email) {
    return http.request(API_ENDPOINTS.getSpaceDetails(), "post", {
      inviteCode,
      email,
    });
  }

  add(data, internal, addAsChannel, channelName) {
    let api = null;
    if (internal) {
      api =
        API_ENDPOINTS.addPatientWithoutInvite() + "?makeActiveInternal=true";
    } else if (addAsChannel) {
      api = API_ENDPOINTS.addPatientAsChannel(channelName);
    } else {
      api = API_ENDPOINTS.addPatient(data.hasOwnProperty("loginUserId"));
    }

    return http.request(api, "post", data);
  }

  addChannel(data, channelName) {
    let api = API_ENDPOINTS.addPatientAsChannel(channelName);
    return http.request(api, "post", data);
  }

  delete(patientId, status, deleteContacts) {
    let api = API_ENDPOINTS.deletePatient(patientId) + "?";
    if (status) {
      api = api + `requestedStatus=${status}`;
    }
    if (deleteContacts) {
      api += "&deleteContacts=true";
    }
    return http.request(api, "delete");
  }

  update(data) {
    return http.request(
      API_ENDPOINTS.update_patient + data.patientId,
      "patch",
      data
    );
  }

  loadSingle(patientId) {
    return http.request(API_ENDPOINTS.loadPatient(patientId));
  }

  loadMembers(patientId, page) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers + "?page=0&size=1000";
    } else {
      api = API_ENDPOINTS.loadMembers(patientId) + "?page=0&size=1000";
    }

    return http.request(api).then((data) => {
      if (data && data._embedded) {
        return {
          data: data._embedded.memberResources || [],
          loadMore: false,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }
    });
  }

  loadPatientsWithBasicDetails(searchTerm, pageNumber) {
    let api =
      API_ENDPOINTS.patientsWithBasicDetails + "?size=20&page=" + pageNumber;
    if (searchTerm) {
      api += "&q=" + searchTerm;
    }

    return http.request(api).then((response) => {
      if (response && response._embedded) {
        return {
          patients: response._embedded.patients || [],
          pageInfo: response.page,
        };
      }
      return {
        patients: [],
        pageInfo: { number: -1 },
      };
    });
  }

  loadCount(criteria) {
    let uiFieldsArr = preProcessFilterFields(criteria);
    let formData = createFormData(criteria, uiFieldsArr);
    return http.request(API_ENDPOINTS.loadPatientsCount(), "post", formData);
  }

  load(page, criteria = {}, all) {
    let pageSize = all ? 100 : 10;
    let endPoint =
      API_ENDPOINTS.loadPatients() + `?page=${page}&size=${pageSize}`;

    let uiFieldsArr = preProcessFilterFields(criteria);
    let formData = createFormData(criteria, uiFieldsArr);
    return http.request(endPoint, "post", formData).then((data) => {
      if (data && data._embedded) {
        return {
          data: data._embedded.getAllPatientResources || [],
          loadMore: data.page.totalPages > page + 1,
          total: data.page.totalElements,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }
    });
  }

  sendMessagesInBulk = (criteria, messageData) => {
    let endPoint = API_ENDPOINTS.loadPatients();
    let uiFieldsArr = preProcessFilterFields(criteria);
    let formData = {
      mode: criteria ? criteria.mode : null,
      actionMode: true,
    };
    if (uiFieldsArr.length > 0) {
      formData.uiFields = uiFieldsArr;
    }
    if (criteria.searchTerm) {
      formData.searchTerm = criteria.searchTerm;
    }

    formData.message = messageData.reminderMessage;
    formData.emailMessage = messageData.reminderEmailMessage;
    formData.messageEmailSubject = messageData.reminderMessageEmailSubject;
    formData.statusMessage = messageData.reminderStatusMessage;

    return http.request(endPoint, "post", formData);
  };

  loadMyPatients(page, criteria) {
    let endPoint =
      API_ENDPOINTS.loadMyPatients + `?page=${page}&size=${PAGE_SIZE}`;
    if (criteria) {
      if (criteria.name) {
        endPoint += "&q=" + criteria.name;
      }
    }

    return http.request(endPoint).then((data) => {
      if (data && data._embedded) {
        return {
          data: data._embedded.patients || [],
          loadMore: data.page.totalPages > page + 1,
          total: data.page.totalElements,
        };
      } else {
        return {
          data: [],
          loadMore: false,
        };
      }
    });
  }

  loadArtifact(artifactLink) {
    return http.request(artifactLink);
  }

  loadPatientProfile(patientId) {
    return http.request(API_ENDPOINTS.getPatientProfile(patientId));
  }

  addNewDocument(patientId, document) {
    return http.request(API_ENDPOINTS.addDocument(patientId), "post", document);
  }

  loadPatientDocuments(patientId, pageNumber, pageSize = 20, selectedTopic) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientDocuments + "&page=0&size=500";
    } else {
      api =
        API_ENDPOINTS.loadPatientDocuments(patientId) +
        `&page=${pageNumber}&size=${pageSize}`;

      if (selectedTopic) {
        api += "&artifactHint=" + selectedTopic;
      }
    }

    return http.request(api).then((data) => {
      // if (role === ROLE_PATIENT) {
      //   return data && data._embedded ? data._embedded.patientDocuments : [];
      // } else {
      return {
        documents:
          data && data._embedded && data._embedded.patientDocuments
            ? data._embedded.patientDocuments
            : [],
        pageInfo: data.page ? data.page : { number: 0, totalPages: 0 },
      };
      // }
    });
  }

  loadPatientDevices(patientId) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientDevices;
    } else {
      api = API_ENDPOINTS.loadPatientDevices(patientId);
    }

    return http.request(api).then((data) => {
      return data && data._embedded ? data._embedded.provisionedDevices : [];
    });
  }

  updateProfileAttribute(patientId, formData) {
    return http.request(
      API_ENDPOINTS.updatePatientProfileAttribute(patientId),
      "post",
      formData
    );
  }

  addMember(patientId, data, admin) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers;
    } else {
      api = API_ENDPOINTS.addMember(
        data.hasOwnProperty("loginUserId"),
        patientId
      );
    }

    return http.request(api, "post", data);
  }

  removeMember(patientId, memberId) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers + `/${memberId}`;
    } else {
      api = API_ENDPOINTS.removeMember(patientId, memberId);
    }
    return http.request(api, "delete");
  }

  editMember(patientId, memberId, data) {
    return http.request(
      API_ENDPOINTS.editMember(patientId, memberId),
      "patch",
      data
    );
  }

  reinvitePatient(patientId, data) {
    return http.request(API_ENDPOINTS.reInvitePatient(patientId), "post", data);
  }

  tagPatient(patientId) {
    return http.request(API_ENDPOINTS.tagPatient(patientId), "post");
  }

  untagPatient(patientId) {
    return http.request(API_ENDPOINTS.tagPatient(patientId), "delete");
  }

  restorePatient(patientId) {
    return http.request(API_ENDPOINTS.restorePatient(patientId), "post");
  }

  addToRecent(patientId) {
    let recents = StorageService.getFromLocalStorage(RECENT_PATIENTS) || [];
    let newRecents = [];

    for (let i = 0; i < recents.length; i++) {
      if (recents[i] !== patientId) {
        newRecents.push(recents[i]);
      }
    }
    newRecents.unshift(patientId);
    StorageService.addToLocalStorage(RECENT_PATIENTS, newRecents.slice(0, 20));
  }

  resetCredentials(patientId, data) {
    return http.request(
      API_ENDPOINTS.resetPatientCredentials(patientId),
      "post",
      data
    );
  }

  activatePatient(patientId, data) {
    return http.request(API_ENDPOINTS.activatePatient(patientId), "post", data);
  }

  deleteDocument(patientId, docId) {
    return http.request(API_ENDPOINTS.documentById(patientId, docId), "delete");
  }

  editDocument(patientId, docId, data) {
    return http.request(
      API_ENDPOINTS.editPatientDocument(patientId, docId),
      "patch",
      data
    );
  }

  loadPatientsForMPSAdmin(searchKey, page = 0) {
    return http
      .request(
        API_ENDPOINTS.MPSAdminPatients() + "?size=20&page=" + page,
        "post",
        { searchKey }
      )
      .then((data) => {
        if (data) {
          return {
            patients: data._embedded ? data._embedded.patients || [] : [],
            pageInfo: data.page,
          };
        } else {
          return {
            patients: [],
            pageInfo: { number: -1 },
          };
        }
      });
  }

  updateStaffSpecialNotes = (patientId, note) => {
    return http.request(
      API_ENDPOINTS.staffSpecialNoteForPatient(patientId),
      "post",
      {
        note,
      }
    );
  };

  getPatientContacts(patientId) {
    return http
      .request(API_ENDPOINTS.patientContacts(patientId))
      .then((data) => {
        if (data) {
          return {
            contacts: data._embedded ? data._embedded.contactPersons || [] : [],
            pageInfo: data.page,
          };
        } else {
          return {
            contacts: [],
            pageInfo: { number: -1 },
          };
        }
      });
  }

  addContact(patientId, data) {
    return http.request(API_ENDPOINTS.patientContacts(patientId), "post", data);
  }

  getPatientContact(patientId, contactId) {
    return http.request(
      API_ENDPOINTS.patientContacts(patientId) + "/" + contactId
    );
  }
  removeContact(patientId, contactId) {
    return http.request(
      API_ENDPOINTS.patientContacts(patientId) + "/" + contactId,
      "delete"
    );
  }

  editContact(patientId, contactId, data) {
    return http.request(
      API_ENDPOINTS.patientContacts(patientId) + "/" + contactId,
      "patch",
      data
    );
  }

  importPatientUsingCSV(
    patientTypeId,
    careTeamId,
    csvFile,
    invitePatientsOnLoad
  ) {
    const formData = new FormData();
    formData.append("files", csvFile);
    let api = API_ENDPOINTS.patientsImportCSV(patientTypeId);
    if (careTeamId) {
      api += "&careTeamId=" + careTeamId;
    }
    if (invitePatientsOnLoad) {
      api += "&invitePatientsOnLoad=true";
    }
    return http
      .request(
        api,
        "post",
        formData,
        null,
        (progressEvent) => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.dispatch(showProgress(uploadProgress));
        },
        120000
      )
      .then((response) => {
        store.dispatch(closeProgress());
        let strings = [];
        if (response._embedded && response._embedded.strings) {
          strings = response._embedded.strings || [];
        }
        return strings;
      })
      .catch((e) => {
        store.dispatch(closeProgress());
      });
  }

  storePatientQuery(query) {
    let uiFieldsArr = preProcessFilterFields(query);
    let formData = createFormData(query, uiFieldsArr);
    formData.name = query.name;
    formData.visibility = query.visibility;
    formData.allowedCareTeams = query.allowedCareTeams;
    return http.request(API_ENDPOINTS.savedPatientQueries, "post", formData);
  }

  loadAllSavedSearches() {
    return http
      .request(API_ENDPOINTS.savedPatientQueries + "?page=0&size=100")
      .then((data) => {
        return data && data._embedded
          ? data._embedded.getPatientsFilterResources || []
          : [];
      });
  }

  deletePatientQuery(queryId) {
    return http.request(
      API_ENDPOINTS.savedPatientQueries + "/" + queryId,
      "delete"
    );
  }

  fetchInteractionsReport(patientId) {
    return http.request(API_ENDPOINTS.interactions_report(patientId));
  }

  addPatient(data) {
    return http.request(API_ENDPOINTS.addPatientV2, "post", data);
  }

  downloadAsCSV(filter) {
    let {
      patientTypeId,
      procedureDateStart,
      procedureDateEnd,
      creationDateStart,
      creationDateEnd,
      lastActiveDateStart,
      lastActiveDateEnd,
      referencedSurveyId,
      allStatus,
      selectedAttributes,
    } = filter;

    let api = API_ENDPOINTS.patientAsCSV(patientTypeId);
    if (procedureDateStart) {
      api +=
        "&procedureDateStart=" + moment(procedureDateStart).format("DDMMYYYY");
    }
    if (procedureDateEnd) {
      api += "&procedureDateEnd=" + moment(procedureDateEnd).format("DDMMYYYY");
    }
    if (creationDateStart) {
      api +=
        "&creationDateStart=" + moment(creationDateStart).format("DDMMYYYY");
    }
    if (creationDateEnd) {
      api += "&creationDateEnd=" + moment(creationDateEnd).format("DDMMYYYY");
    }

    if (lastActiveDateStart) {
      api +=
        "&lastActiveDateStart=" +
        moment(lastActiveDateStart).format("DDMMYYYY");
    }
    if (lastActiveDateEnd) {
      api +=
        "&lastActiveDateEnd=" + moment(lastActiveDateEnd).format("DDMMYYYY");
    }
    if (allStatus) {
      api += "&allStatus=true";
    }
    if (referencedSurveyId) {
      api += "&referencedSurveyId=" + referencedSurveyId;
    }

    let body = {};
    if (selectedAttributes && selectedAttributes.length >= 1) {
      body.attributeNames = selectedAttributes.map((a) => a.name);
    }

    return http.request(api, "post", body);
  }

  updateChannel(channelId, channelData) {
    return http.request(API_ENDPOINTS.channel(channelId), "patch", channelData);
  }

  loadDialysisCenters(patientId) {
    let api = API_ENDPOINTS.dialysisCenters(patientId);
    return http.request(api).then((data) => {
      return {
        dialysisCenters:
          data && data.dialysisCenters && data.dialysisCenters.content
            ? data.dialysisCenters.content
            : [],
        patientPosition: data.patientPosition,
      };
    });
  }

  downloadPatientData = (patientId) => {
    return http.request(
      API_ENDPOINTS.patientData(patientId),
      "post",
      null,
      null,
      null,
      null,
      {
        responseType: "arraybuffer",
      }
    );
  };

  resetCredentialsBySupport(patientId, data) {
    return http.request(
      API_ENDPOINTS.resetPatientCredentialsBySupport(patientId),
      "post",
      data
    );
  }

  loadChargeItems(patientId, pageNumber, pageSize = 20) {
    let api =
      API_ENDPOINTS.chargeItems(patientId) +
      `?page=${pageNumber}&size=${pageSize}`;

    return http.request(api).then((data) => {
      return {
        chargeItems:
          data && data._embedded && data._embedded.chargeItems
            ? data._embedded.chargeItems
            : [],
        pageInfo: data.page ? data.page : { number: 0, totalPages: 0 },
      };
    });
  }

  sendPatientConversation(messageId, text) {
    return http.request(
      PATIENT_API_ENDPOINTS.sendPatientConversation(messageId),
      "post",
      text
    );
  }

  sendStaffConversation(patientId, messageId, text) {
    return http.request(
      PATIENT_API_ENDPOINTS.sendStaffConversation(patientId, messageId),
      "post",
      text
    );
  }

  sendConversationRead(messageId, textIdArray) {
    return http.request(
      PATIENT_API_ENDPOINTS.sendConversationRead(messageId),
      "post",
      textIdArray
    );
  }

  loadEpisodesOfCare(patientId) {
    return http.request(
      API_ENDPOINTS.getEpisodesOfCare(patientId), 
      "get"
    )
  }

}


const patientsService = new PatientsService();

export default patientsService;
